<script setup lang="ts">
    import refreshProgress from '@/utilities/refreshProgress'
    import { watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { storeToRefs } from 'pinia'
    import { useAuthStore } from '@stores/authStore'
    import { initializeProductFruits } from '@/utilities/productFruits'
    import { initialLoad, initialRoute } from '@/utilities/directRouteManager'
    const authStore = useAuthStore()
    const { token } = storeToRefs(authStore)

    const { loading, progress, runRefresh } = refreshProgress
    const { loadingTitle } = refreshProgress

    import { useI18n } from 'vue-i18n'
    import { nextTick } from 'vue'
    import initStores from '@/stores/_initRefreshStores'
    initStores()

    const { t } = useI18n()

    const route = useRoute()
    const router = useRouter()

    watch(
        [token, () => route.name],
        async ([token, rt]) => {
            if (token && rt) {
                console.log('route name: ', rt)
                console.log('initialRoute: ', initialRoute.value)
                if (rt === 'login' || rt === undefined) {
                    if (initialRoute.value) {
                        console.log('route to: ', { name: authStore.defaultView, params: initialRoute.value.params, query: initialRoute.value.query })
                    }
                    const routeObj =
                        initialRoute.value && ['login', 'public', ''].includes(initialRoute.value.name)
                            ? { name: authStore.defaultView, params: initialRoute.value.params, query: initialRoute.value.query }
                            : initialRoute.value

                    if (routeObj) {
                        await nextTick()
                        // This will guid you to the communication if the params was not defined by default
                        // communication/calendar/:period
                        const routeName = routeObj.name
                        if (routeName === 'calendar' && !route.params.period) {
                            router.push({ name: 'calendar', params: { period: 'month' } })
                        } else {
                            router.push({ name: routeObj.name, params: routeObj.params, query: routeObj.query })
                        }
                    }
                }
                if (!initialLoad.value) return
                initialLoad.value = false
                await runRefresh()
                initializeProductFruits()
            }
            if (!token) {
                initialLoad.value = true
            }
        },
        { immediate: true }
    )
</script>
<template>
    <v-layout v-if="loading" class="c-loading-screen">
        <v-container>
            <img src="/favicon-white.png" alt="logo" width="50" />
            <h1>De Contentkalender</h1>
            <h2 v-if="loadingTitle">{{ loadingTitle }}</h2>
            <div class="text-center">
                <v-progress-circular :rotate="360" :size="80" :width="10" :model-value="progress" color="primary">
                    <template v-slot:default> {{ progress }}% </template>
                </v-progress-circular>
                <p>{{ t('GeneralKeys.appLoading') }}</p>
            </div>
        </v-container>
    </v-layout>
    <slot v-else />
</template>
<style lang="scss" scoped>
    .v-layout {
        height: 100dvh;
    }

    .c-loading-screen {
        display: grid;
        place-items: center;
        width: 100dvw;
        height: 100vh;
        background: rgb(2, 0, 36);
        background: linear-gradient(0deg, var(--color-grey-700) 10%, var(--color-grey-400) 90%);

        gap: 2rem;

        .v-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            color: white;
        }

        h1 {
            font-family: 'Varela Round', sans-serif;
            font-weight: 400;
            word-break: break-word;
        }
        h2 {
            font-family: 'Varela Round', sans-serif;
            font-weight: 400;
            word-break: break-word;
        }

        p {
            color: var(--color-primary);
            text-transform: uppercase;
            margin-block-start: 1rem;
        }
    }
</style>
